
  .settingpage {
    padding: 20px auto;
    .countdetails {
      margin-bottom: 10px;
    }
   .title {
    margin: 0;
  }
 
  }
  
  