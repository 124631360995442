.storyboard {
    padding: 20px;
    border: 1px solid black;
    .outlined-size-small {
        z-index: 0 !important;
        width: 100% !important;
      }
    .below-section {
        margin-top: 10px;
        
    }
    .Dropbox{
        margin: 5px 0px 5px 10px;
    }
    .subsectionBox {
        margin-right: 10px;
        margin-top: 10px;

    }
    .displayfacts {
        margin-left: 0;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        padding-right: 0;
    }
    .buttonstyle{
        margin-top: 20px;
    }
}
