.factfinder {
  padding: 20px;
  .loadingcomponent {
    text-align: center;
    margin-top: 30px;
  }
  .summarysection {
    margin-top: 20px;
    .loadingcomponent {
      text-align: center;
    }
    .paginationComponent {
      margin: 10px auto;
    }
  }
}
