.navbar {
  background-color: #000000 !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  
  .MuiToolbar-root {
    justify-content: space-between;
    height: 55px;
    min-height: 55px;
  }
}

.menu {
  background-color: rgba(0, 0, 0, 0.6);
  
  .MuiPaper-root {
    color: white;
    background-color: black;
    top: 56px !important;
  }
  
  .MuiMenuItem-root {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 20px;
    
    &:hover {
      background-color: #24ADE4 !important;
    }
  }
}

.link {
  text-decoration: none;
  color: white;
  font-size: 22px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 2px solid white;
  line-height: 21px;
  
  @media (max-width: 1532px) {
    font-size: 18px;
  }
}

.disabledLink {
  pointer-events: none;
  color: #b5b5b5;
}

.navlinks {
  margin-left: 20px;
  display: flex;
  justify-content: center;
}

.logo {
  max-width: 300px;
  cursor: pointer;
}

.right {
  display: flex;
  justify-content: center;
}

.rightText {
  padding: 10px 20px;
  font-size: 20px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  @media (max-width: 900px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.profileIcon {
 height: 40px !important;
 width: 40px !important;
 border-radius: 50%;
  
  @media (max-width: 768px) {
    font-size: 2.2rem;
    border-radius: 50%;
  }
}
.logoImg {
  max-width: 640px;
  max-height: 100px;
  margin: 0px 15px;
  
  @media (max-width: 768px) {
    max-width: 285px;
    height: auto;
  }
}

.menuButton {
  color: #24ADE4;
  
  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
}

.drawer {
  .MuiDrawer-paperAnchorLeft {
    background-color: white;
  }
  
  .MuiDrawer-paper {
    top: 84px;
  }
  
  .MuiTypography-body1 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    a {
      color: rgb(0, 0, 0);
    }
  }
  
  .MuiSvgIcon-root {
    fill: #28A4F2;
    font-size: 2rem;
  }
  
  .MuiButton-root {
    color: white;
  }
}