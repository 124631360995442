.footer {
  background-color:#343542;
  color: hsla(0,0%,100%,.5);
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .footer-links {
    display: flex;
    justify-content: center;
    width: 70%;
    align-items: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 12px;
    white-space: pre-wrap;
    a, a:active, a:focus, a:hover, a:link, a:visited {
      color: hsla(0,0%,100%,.5);
      text-decoration: none;
    }
    .disabledLink {
      pointer-events: none;
      color: #b5b5b5;
    }

    @media (max-width: 768px) {
      width: 100%;
      font-size: 9px;
      display: flex;
      justify-content: center;
      flex-flow: wrap;
    };

    @media (max-width: 414px) {
      font-size: 8px;
    }
  }

  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: inherit;
    margin-top: -5px;
    @media (max-width: 1170px) {
      display: none;
      };
  }

  .version {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 11px;
    color: hsla(0, 0%, 100%, 0.5);
    padding-left: 10px;
    padding-right: 10px;
    @media (max-width: 1170px) {
      display: none;
      };
  }

  .scribelogo {
    max-width: 262px;
    height: auto;
    padding-top: 3px;
  }
  .reachlogo {
    width: 65px;
    height: auto;
    padding-left: 10px;
  }
  span {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 15px;
    padding-left: 10px;
  }

  p {
    padding-left: 10px ;
  }
  strong {
    padding-right: 5px;
  }
}