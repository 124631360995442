.templatepage {
  padding: 20px;

  .insertelement {
    margin: 0 !important;
    font-weight: bold;
  }
  .left-section {
    padding: 20px;
    // border-right: thin #000 solid;
    height: auto;
  }
}
.selectbox {
  margin-top: 10px;
  z-index: 0 !important;
}
.group-section {
  margin-bottom: 20px;
}
.addvariable-section {
  padding-left: 10px;
}
.popupstyle {
  text-align: end !important;
}
.popupBox {
  margin-top: 10px !important;
}
.date-picker {
  margin-bottom: 10px;
  width: 100%;
}
.selecttemplatepage {
  padding: 20px;
}
.textarea {
  width: 100%;
  height: 150px;
}
.template-preview {
  margin-top: 10px;
}
.ai-writer {
  .left-section {
    padding: 0px 20px;
    height: auto;
  }
}
