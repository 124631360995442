.blinking-cursor {
    padding-left: 2px;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}
