.ai-writer {
  .left-section {
    padding: 0px 20px;
    height: auto;
  }
  .titlename {
    margin-top: 0;
  }
  .date-picker {
    margin-bottom: 10px;
    width: 100%;
  }
  .select-box {
    margin-bottom: 10px;
  }
  .headingsection {
    margin: 5px 0px;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 99.2%;
    height: 30px;
    margin-bottom: 15px;
  }
  .react-datepicker__input-container input {
    padding-left: 8px;
    background-color: hsl(0, 0%, 100%);
    border-color: rgb(204 204 204);
    border-radius: 3px;
    border-width: 1px;
  }
  .loadingcomponent {
    position: absolute;
    top: 45%;
    left: 65%;
  }
}